module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.askyourdeveloper.com","noQueryString":true,"noTrailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Whitney"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"f55c0da2835b4111b601d55db70c1ef7","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production"}},
    },{
      plugin: require('../plugins/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5NL992W","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby","cspNonce":"**TEST**"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ask Your Developer by Jeff Lawson","short_name":"Ask Your Developer","start_url":"/","icons":[{"src":"/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/images/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"background_color":"#ef373e","theme_color":"#ef373e","display":"standalone"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
